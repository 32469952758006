<template>
  <div
    class="studio-mobile-menu"
    :class="{ open: isStudioMobileProfileMenuOpen }"
  >
    <header>
      <StudioLogo class="studio-logo" :studio="studio" />
      <span class="close" @click="closeMainNav">
        <i class="far fa-times"></i>
      </span>
    </header>
    <nav class="mx-3">
      <div class="py-3" v-if="userName">
        <h4 class="mb-3">{{ userName }}</h4>
        <a :href="dashboardLink" class="undecorated-router-link">
          <SidebarNavItem
            v-if="teacher && !isPWA"
            @click="closeMainNav"
            :text="$t('STUDIO.LINKS.GO_TO_DASHBOARD')"
          />
        </a>
        <span
          id="classcard"
          class="pointer me-2 mt-4 mb-1 px-2 position-relative"
          v-if="numberOfThisStudioPunches > 0"
        >
          <NuxtLink
            class="undecorated-router-link"
            :to="{ name: 'student-redemptions' }"
            @click="closeMainNav"
          >
            <i class="fal fa-ticket-alt c-brand-color me-2 rotate-35"></i>
          </NuxtLink>
          <div class="circle d-flex align-items-center justify-content-center">
            {{ numberOfThisStudioPunches }}
          </div>
        </span>
        <!--        <span-->
        <!--          id="messages"-->
        <!--          class="pointer me-2 mt-4 mb-2 px-1 position-relative"-->
        <!--          v-if="numberOfNotifications > 0"-->
        <!--        >-->
        <!--          <i class="far fa-bell c-brand-color me-3"></i>-->
        <!--          <div class="circle d-flex align-items-center justify-content-center">-->
        <!--            {{ numberOfNotifications }}-->
        <!--          </div>-->
        <!--        </span>-->
        <NuxtLink
          :to="{ name: 'student-settings' }"
          class="undecorated-router-link"
        >
          <SidebarNavItem
            @click="closeMainNav"
            :text="$t('STUDIO.LINKS.MY_SETTINGS')"
          />
        </NuxtLink>
        <NuxtLink
          :to="{
            name: 'student-community-profile-view',
            params: { id: user.community_profile_id },
          }"
          class="undecorated-router-link"
        >
          <SidebarNavItem
            @click="closeMainNav"
            :text="$t('STUDIO.LINKS.MY_PROFILE')"
          />
        </NuxtLink>
        <NuxtLink
          class="undecorated-router-link"
          v-if="!isPWA"
          :to="{ name: 'studio-list' }"
        >
          <SidebarNavItem :text="$t('STUDIO.LABELS.MY_STUDIOS')" />
        </NuxtLink>
        <a
          v-if="!teacher && !isPWA"
          class="undecorated-router-link"
          :href="`${getAdminBaseFrontendURL}signup?enable-coupon=true`"
        >
          <SidebarNavItem :text="$t('STUDIO.LINKS.UPGRADE_TO_TEACHER')" />
        </a>
        <a class="undecorated-router-link" @click="logout">
          <SidebarNavItem :text="$t('GENERAL.LINK.LOGOUT')" />
        </a>
      </div>
    </nav>
    <ViewAsTeacherFooter class="view-as-teacher-footer" />
  </div>
</template>

<script lang="ts">
import { useUserStore, useStudioStore, useCommunityStore, useGlobalsStore } from '@/stores';
import { mapState } from 'pinia';

import SidebarNavItem from './SidebarNavItem.vue';
import StudioLogo from '@/components/navigation/StudioLogo.vue';
import { isOfficialDomain, replaceURLWithCurrentDomain } from '@/utils/url';
import ViewAsTeacherFooter from '@/components/navigation/ViewAsTeacherFooter.vue';

export default {
  name: 'StudioMobileProfileMenu',
  components: {
    SidebarNavItem,
    StudioLogo,
    ViewAsTeacherFooter,
  },
  data() {
    return {
      isUserCollapseOpen: true,
    };
  },
  setup() {
    const globalsStore = useGlobalsStore();
    const userStore = useUserStore();
    const url = useRequestURL();

    return {
      globalsStore,
      userStore,
      url,
    }
  },
  computed: {
    ...mapState(useUserStore, {
      numberOfThisStudioPunches: 'numberOfThisStudioPunches',
      isLoggedIn: 'isLoggedIn',
      teacherMagicCode: 'teacherMagicCode',
      user: 'user',
      teacher: 'teacher',
    }),
    ...mapState(useCommunityStore, ['numberOfNotifications']),
    ...mapState(useGlobalsStore, ['isPWA', 'isStudioMobileProfileMenuOpen']),
    ...mapState(useStudioStore, {
      studio: 'currentStudio',
    }),
    getAdminBaseFrontendURL() {
      const host = this.url.host;
      if (isOfficialDomain(host)) {
        return replaceURLWithCurrentDomain(this.url);
      }

      return process.env.VUE_APP_FRONTEND_ADMIN_BASE_URL;
    },
    userName() {
      if (!this.user) return;
      return this.user.user_full_name;
    },
    studioURL() {
      return this.$studioURL;
    },
    dashboardLink() {
      return `${this.getAdminBaseFrontendURL}dashboard?magic_code=${this.teacherMagicCode}`;
    },
  },
  methods: {
    closeMainNav() {
      this.globalsStore.openStudioMobileProfileMenu(false);
    },
    showLogin() {
      this.$emit('login');
    },
    logout() {
      const currentRoute = this.$route;
      if (currentRoute.matched.some(record => record.meta.requiresAuth)) {
        this.$router.push({ name: 'studio-logout' });
      } else {
        this.userStore.logout();
      }

      this.closeMainNav();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';

.studio-mobile-menu {
  display: none;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $zindex-fixed + 3; // After studio product and events calendar header

  height: 100vh;
  width: 100vw;

  background-color: white;

  &.open {
    @include media-breakpoint-down(sm) {
      display: flex;
    }
  }
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5rem;
  border-bottom: 1px solid $light-gray;
  height: $shop-topbar-height + 1px;
}

.close {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: $medium-gray;

  width: 42px;
  height: 2.5rem;

  &:hover,
  &:active {
    color: var(--brand-color, $coral);
  }
}

.studio-logo {
  width: auto;
  height: 100%;
  max-width: 100%;
  min-width: auto;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  margin: 0 0.5rem;
}

.circle {
  background-color: var(--brand-color, $coral);
  height: 19px;
  width: 19px;
  border-radius: 50%;
  border: 2px solid $white;
  text-align: center;
  font-size: 8px;
  font-weight: 700;
  font-family: 'Poppins';
  color: $white;
  position: absolute;
  bottom: -4px;
  right: 10px;
  padding-top: 2px;
}
</style>
