<template>
  <div class="studio-mobile-menu" :class="{ open: isMainNavOpen }">
    <header>
      <StudioLogo class="studio-logo" :studio="studio" />
      <span class="close" @click="closeMainNav">
        <i class="far fa-times"></i>
      </span>
    </header>
    <nav class="mx-3">
      <a
        v-if="studio.custom_nav_label_1"
        :href="studio.custom_nav_link_1"
        target="_blank"
      >
        <SidebarNavItem
          @click="closeMainNav"
          :text="studio.custom_nav_label_1"
        />
      </a>
      <a
        v-if="studio.custom_nav_label_2"
        :href="studio.custom_nav_link_2"
        target="_blank"
      >
        <SidebarNavItem
          @click="closeMainNav"
          :text="studio.custom_nav_label_2"
        />
      </a>
      <a
        v-if="studio.custom_nav_label_3"
        :href="studio.custom_nav_link_3"
        target="_blank"
      >
        <SidebarNavItem
          @click="closeMainNav"
          :text="studio.custom_nav_label_3"
        />
      </a>
    </nav>
  </div>
</template>

<script lang="ts">
import { mapState } from 'pinia';
import { useStudioStore, useGlobalsStore } from '@/stores';

import SidebarNavItem from './SidebarNavItem.vue';
import StudioLogo from '@/components/navigation/StudioLogo.vue';

export default {
  name: 'StudioMobileMenu',
  components: {
    SidebarNavItem,
    StudioLogo,
  },
  setup() {
    const studioStore = useStudioStore();
    const globalsStore = useGlobalsStore();

    return {
      studioStore,
      globalsStore,
    };
  },
  computed: {
    ...mapState(useStudioStore, {
      studio: state => state.currentStudio,
    }),
    ...mapState(useGlobalsStore, {
      isMainNavOpen: state => state.isMainNavOpen,
    }),
  },
  methods: {
    closeMainNav() {
      this.globalsStore.openMainNav(false);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';

.studio-mobile-menu {
  display: none;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $zindex-fixed + 3; // After studio product and events calendar header

  height: 100vh;
  width: 100vw;

  background-color: white;

  &.open {
    @include media-breakpoint-down(md) {
      display: flex;
    }
  }
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5rem;
  border-bottom: 1px solid $light-gray;
  height: $shop-topbar-height + 1px;
}

.close {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: $medium-gray;

  width: 42px;
  height: 2.5rem;

  &:hover,
  &:active {
    color: var(--brand-color, $coral);
  }
}

.studio-logo {
  width: auto;
  height: 100%;
  max-width: 100%;
  min-width: auto;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  margin: 0 0.5rem;
}
</style>
