<template>
  <div v-if="studio">
    <HMDropdownMenu
      class="d-md-none"
      v-model="showNavDropdown"
      :closeOnClickOutside="true"
    >
      <HMButton
        color="brand"
        class="w-100 px-3"
        :class="{
          'button-nav-opened': showNavDropdown,
        }"
      >
        <div class="d-flex justify-content-between w-100 align-items-center">
          <span>
            <i class="pe-2" :class="`far fa-${selectedItem.icon}`"></i>
            {{ selectedItem.label }}
          </span>
          <i
            class="ps-2 fas"
            :class="{
              'fa-chevron-up': showNavDropdown,
              'fa-chevron-down': !showNavDropdown,
            }"
          ></i>
        </div>
      </HMButton>
      <template #dropdown>
        <div class="d-flex flex-column w-100 dropdown">
          <NuxtLink
            v-for="item in dropdownItems"
            :key="item.routeName"
            :to="{ name: item.routeName }"
            class="undecorated-router-link dropdown-item"
            @click.native="() => (showNavDropdown = false)"
          >
            <i :class="`me-2 far fa-${item.icon}`"></i>
            <span>
              {{ item.label }}
            </span>
          </NuxtLink>
        </div>
      </template>
    </HMDropdownMenu>
    <nav class="d-none d-md-block">
      <NuxtLink
        v-for="(item, $index) in items"
        :key="item.routeName"
        :to="{ name: item.routeName }"
      >
        <HMButton
          :class="{ 'ms-3': $index }"
          :color="
            item.routeName === selectedRouteName ? 'brand' : 'outline-brand'
          "
        >
          <i :class="`me-2 far fa-${item.icon}`"></i>
          {{ item.label }}
        </HMButton>
      </NuxtLink>
    </nav>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import { useStudioStore } from '@/stores/studio';
import { useUserStore } from '@/stores/user';

export default defineComponent({
  name: 'StudentNavigation',
  computed: {
    ...mapState(useStudioStore, {
      studio: 'currentStudio',
    }),
    ...mapState(useUserStore, {
      isLoggedIn: 'isLoggedIn',
    }),
    selectedRouteName() {
      return this.$route.name;
    },
    selectedItem() {
      return (
        this.items.find(item => item.routeName === this.selectedRouteName) ||
        this.items[0]
      );
    },
    dropdownItems() {
      return this.items.filter(
        item => item.routeName !== this.selectedRouteName
      );
    },
    items() {
      const allItems = [
        {
          label: this.$t('STUDIO.LINKS.EXPLORE'),
          icon: 'compass',
          routeName: 'studio-dashboard',
        },
        {
          label: this.$t('STUDIO.LINKS.MY_PRODUCTS'),
          icon: 'box-open',
          routeName: 'studio-products',
        },
        {
          label: this.$t('STUDIO.LINKS.MY_EVENTS'),
          icon: 'calendar-alt',
          routeName: 'studio-events',
          hide: !this.studio?.has_registered_events,
        },
        {
          label: this.$t('STUDIO.LINKS.COMMUNITY'),
          icon: 'comments',
          routeName: 'studio-community',
          hide: !this.studio?.has_community_groups,
        },
        {
          label: this.$t('STUDIO.LINKS.PODCASTS'),
          icon: 'microphone',
          routeName: 'studio-podcasts',
          hide: !this.studio?.has_podcasts,
        },
        {
          label: this.$t('STUDIO.LINKS.FAVORITES'),
          icon: 'heart',
          routeName: 'studio-my-favorites',
        },
      ];

      return allItems.filter(item => !item.hide);
    },
  },
  data() {
    return {
      showNavDropdown: false,
    };
  },
  methods: {},
});
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';

.button-nav-opened {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

:deep(.dropdown-menu) {
  width: 100%;
  border-radius: 0;
  box-shadow: none;
  padding: 0;
  margin-top: 0;
  border: 1px solid var(--brand-color, $coral);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.dropdown-item {
  width: 100%;
  padding: 0.5rem 1rem;
  color: var(--brand-color, $coral);

  &:active {
    background-color: white;
  }

  + .dropdown-item {
    border-top: 1px solid var(--brand-color, $coral);
  }
}
</style>
