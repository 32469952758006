<template>
  <div>
    <div
      v-if="showCookie"
      class="hm-cookie-notice-container d-flex flex-row justify-content-between align-items-center py-2"
    >
      <div class="px-3 px-md-4">
        {{ $t('GENERAL.TEXT.COOKIE_NOTICE_TEXT') }}
        <span class="d-inline-block pointer" @click="accept">
          <span class="hm-cookie-notice--btn">
            {{ $t('GENERAL.BUTTON.COOKIE_NOTICE') }}
          </span>
        </span>
      </div>
      <div class="text-end me-3 ms-md-4">
        <i 
          class="close-icon far fa-times"
          @click="accept"
        ></i>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useGlobalsStore } from '@/stores/globals';

const globalsStore = useGlobalsStore();
const route = useRoute();

const showCookie = computed(() => {
  return globalsStore.showCookieNotice && !route.meta.hideCookieConsent;
});

const accept = () => {
  globalsStore.setShowCookieNotice(false);
};
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';

.hm-cookie-notice-container {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: $charcoal;
  color: $white;
  z-index: 99999;
  .hm-cookie-notice--btn {
    border-bottom: 2px solid $coral;
    font-weight: 500;
    &:hover {
      opacity: 0.8;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-out;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.close-icon {
  cursor: pointer;
  color: white;
  font-size: 24px;
}
</style>
