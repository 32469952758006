<template>
  <div>
    <div
      class="shop-topbar fixed-top d-flex"
      :class="{ 'shop-topbar--lg': displayLarge }"
      v-if="studio"
    >
      <span
        class="back-button pointer d-md-none"
        v-if="$route.meta.hideMobileNav"
        @click="$router.go(-1)"
      >
        <i class="fal fa-chevron-left"></i>
      </span>
      <StudioLogo class="logo-image mx-2 mx-lg-4" :studio="studio" />
      <div class="d-flex justify-content-between w-100">
        <HMTabs
          class="d-none d-md-inline-flex page-tabs border-0 h-100"
          :modelValue="selectedPageName"
        >
          <HMTab
            name="studio-dashboard"
            class="pb-0 me-3"
            v-if="isLoggedIn"
            type="text-active"
          >
            <NuxtLink
              class="undecorated-router-link"
              :to="{ name: 'studio-dashboard' }"
            >
              {{ $t('STUDIO.LINKS.DASHBOARD') }}
            </NuxtLink>
            <span class="tab-divider border-start py-3 ms-3"></span>
          </HMTab>
          <HMTab name="studio-shop" class="pb-0" type="text-active">
            <NuxtLink
              class="undecorated-router-link"
              :to="{ name: 'studio-shop' }"
            >
              {{ $t('STUDIO.LINKS.SHOP') }}
            </NuxtLink>
          </HMTab>
          <HMTab
            name="studio-calendar"
            class="pb-0"
            v-if="studio.has_events"
            type="text-active"
          >
            <NuxtLink
              class="undecorated-router-link"
              :to="{ name: 'studio-calendar' }"
            >
              {{ $t('STUDIO.LINKS.CALENDAR') }}
            </NuxtLink>
          </HMTab>
          <HMTab
            name="custom_1"
            class="pb-0"
            v-if="studio.custom_nav_label_1"
            type="text-active"
          >
            <a
              :href="studio.custom_nav_link_1"
              class="undecorated-router-link"
              target="_blank"
            >
              {{ studio.custom_nav_label_1 }}
            </a>
          </HMTab>
          <HMTab
            name="custom_1"
            class="pb-0"
            v-if="studio.custom_nav_label_2"
            type="text-active"
          >
            <a
              :href="studio.custom_nav_link_2"
              class="undecorated-router-link"
              target="_blank"
            >
              {{ studio.custom_nav_label_2 }}
            </a>
          </HMTab>
          <HMTab
            name="custom_1"
            class="pb-0"
            v-if="studio.custom_nav_label_3"
            type="text-active"
          >
            <a
              :href="studio.custom_nav_link_3"
              class="undecorated-router-link"
              target="_blank"
            >
              {{ studio.custom_nav_label_3 }}
            </a>
          </HMTab>
        </HMTabs>

        <span
          class="d-none d-md-inline-flex align-items-center float-end h-100"
        >
          <NavItem
            v-if="showLogin"
            class="d-none d-md-flex"
            :text="$t('GENERAL.LINK.LOGIN')"
            @click="openLoginModal"
          />
          <span v-if="isLoggedIn" class="d-flex me-4">
            <span
              id="classcard"
              class="pointer me-2 px-2 pe-3 border-end position-relative"
              v-if="numberOfThisStudioPunches > 0"
              v-tooltip="{
                content: $t(
                  'STUDIO.TEXT.YOU_HAVE_REDEMPTIONS_VARIANT',
                  { count: numberOfThisStudioPunches },
                  numberOfThisStudioPunches
                ),
                delay: 100,
                placement: 'bottom',
                container: 'body',
              }"
            >
              <NuxtLink
                class="undecorated-router-link"
                :to="{ name: 'student-redemptions' }"
              >
                <i class="fal fa-ticket-alt c-brand-color me-1 rotate-35"></i>
                <div
                  class="circle d-flex align-items-center justify-content-center"
                >
                  {{ numberOfThisStudioPunches }}
                </div>
              </NuxtLink>
            </span>
            <span
              class="pointer me-2 px-2 pe-3 border-end position-relative"
              @click="onMessageIconClick"
            >
              <i class="far fa-comment c-brand-color me-1"></i>
              <div
                v-if="numberOfChatNotifications"
                class="circle d-flex align-items-center justify-content-center"
              >
                {{ numberOfChatNotifications }}
              </div>
            </span>
            <span
              class="pointer me-2 px-2 pe-3 border-end position-relative"
              @click="onNotificationIconClick"
              ref="notificationsTrigger"
            >
              <i class="far fa-bell c-brand-color me-1"></i>
              <div
                v-if="notificationsCount"
                class="circle d-flex align-items-center justify-content-center"
              >
                {{ notificationsCount }}
              </div>
            </span>
            <NuxtLink
              v-if="isLoggedIn && user"
              class="undecorated-router-link"
              :to="{
                name: 'student-community-profile-view',
                params: { id: user.community_profile_id },
              }"
            >
              <HMMemberIcon
                :icon="user.community_profile_photo"
                :fullName="`${user.community_first_name} ${user.community_last_name}`"
                :title="`${user.community_first_name} ${user.community_last_name}`"
                :color="studio.primary_color"
                :fontSize="14"
                size="25px"
                class="me-2"
              />
            </NuxtLink>
            <button
              id="trigger"
              class="destyled-button pointer d-flex flex-nowrap align-items-center"
            >
              {{ userName }}
              <i 
                class="ms-2 rotate far fa-angle-down"
                :class="{
                  down: showPopoverMenu,
                }"
              ></i>
            </button>
            <HMPopover
              target="trigger"
              placement="bottom-end"
              v-model="showPopoverMenu"
            >
              <a
                v-if="teacher"
                class="undecorated-router-link"
                :href="dashboardLink"
              >
                <HMPopoverMenuItem v-if="teacher">
                  {{ $t('STUDIO.LINKS.GO_TO_DASHBOARD') }}
                </HMPopoverMenuItem>
              </a>
              <NuxtLink
                class="undecorated-router-link"
                :to="{ name: 'student-settings' }"
              >
                <HMPopoverMenuItem>
                  {{ $t('STUDIO.LINKS.MY_SETTINGS') }}
                </HMPopoverMenuItem>
              </NuxtLink>
              <NuxtLink
                v-if="isLoggedIn && user"
                class="undecorated-router-link"
                :to="{
                  name: 'student-community-profile-view',
                  params: { id: user.community_profile_id },
                }"
              >
                <HMPopoverMenuItem>
                  {{ $t('STUDIO.LINKS.MY_PROFILE') }}
                </HMPopoverMenuItem>
              </NuxtLink>
              <NuxtLink
                class="undecorated-router-link"
                :to="{ name: 'studio-list' }"
              >
                <HMPopoverMenuItem>
                  {{ $t('STUDIO.LABELS.MY_STUDIOS') }}
                </HMPopoverMenuItem>
              </NuxtLink>
              <a
                v-if="!teacher"
                class="undecorated-router-link"
                :href="`${getAdminBaseFrontendURL}signup?enable-coupon=true`"
              >
                <HMPopoverMenuItem>
                  {{ $t('STUDIO.LINKS.UPGRADE_TO_TEACHER') }}
                </HMPopoverMenuItem>
              </a>
              <a class="undecorated-router-link" @click="logout">
                <HMPopoverMenuItem>
                  {{ $t('GENERAL.LINK.LOGOUT') }}
                </HMPopoverMenuItem>
              </a>
            </HMPopover>
          </span>
        </span>
        <span
          class="d-inline-flex d-md-none justify-content-end align-items-center h-100 w-100"
        >
          <span v-if="isLoggedIn" class="d-flex">
            <span
              class="pointer me-2 px-2 position-relative"
              @click="onMessageIconClick"
            >
              <i class="far fa-comment c-brand-color me-1"></i>
              <div
                v-if="numberOfChatNotifications"
                class="circle d-flex align-items-center justify-content-center"
              >
                {{ numberOfChatNotifications }}
              </div>
            </span>
            <span
              class="pointer me-2 px-2 position-relative"
              @click="onNotificationIconClick"
              ref="notificationsTrigger"
            >
              <i class="far fa-bell c-brand-color me-1"></i>
              <div
                v-if="notificationsCount"
                class="circle d-flex align-items-center justify-content-center"
              >
                {{ notificationsCount }}
              </div>
            </span>
            <HMMemberIcon
              v-if="isLoggedIn && user"
              @click.native="openStudioMobileProfileMenu"
              :icon="user.community_profile_photo"
              :fullName="`${user.community_first_name} ${user.community_last_name}`"
              :title="`${user.community_first_name} ${user.community_last_name}`"
              :color="studio.primary_color"
              :fontSize="14"
              size="25px"
              class="me-2"
            />
          </span>
          <HMButton
            color="outline-gray"
            v-else
            @click="openLoginModal"
            class="me-1"
          >
            {{ $t('GENERAL.LINK.LOGIN') }}
          </HMButton>
          <div
            class="open-main-nav-button d-md-none d-inline-flex h-100 justify-content-end"
            @click="openMainNav"
          >
            <i class="far fa-bars"></i>
          </div>
        </span>
        <StudioMobileMenu />
        <StudioMobileProfileMenu @login="openLoginModal" />
      </div>
      <CommunityNotifications
        :class="{ 'top-offset-lg': displayLarge, 'top-offset': !displayLarge }"
        :style="getNotificationsStyle()"
        v-if="showNotifications"
        :displayLarge="displayLarge"
        :isStudio="interactAsStudio"
        :magicCode="studentMagicCode"
        :showHeader="true"
        v-click-away="hideNotifications"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import { useUserStore, useStudioStore, useCommunityStore, useGlobalsStore } from '@/stores';
import StudioMobileMenu from '@/components/navigation/StudioMobileMenu.vue';
import StudioMobileProfileMenu from '@/components/navigation/StudioMobileProfileMenu.vue';
import NavItem from '@/components/navigation/NavItem.vue';
import StudioLogo from '@/components/navigation/StudioLogo.vue';
import StudentLogin from '@/components/signup/StudentLogin.vue';
import { isOfficialDomain, replaceURLWithCurrentDomain } from '@/utils/url';
import CommunityNotifications from '@/components/community/CommunityNotifications.vue';
import { directive } from "vue3-click-away";
import { isTouchDevice } from '@/utils/detect-device';

export default defineComponent({
  name: 'StudioTopBar',
  directives: {
    ClickAway: directive
  },
  components: {
    StudioMobileMenu,
    StudioMobileProfileMenu,
    NavItem,
    StudioLogo,
    CommunityNotifications,
  },
  setup() {
    const globalsStore = useGlobalsStore();
    const url = useRequestURL();

    return {
      globalsStore,
      url,
    }
  },
  data() {
    return {
      selectedPageName: '',
      displayLarge: false,
      showPopoverMenu: false,
      showNotifications: false,
      //numberOfMessages: 1, // hardcoded till server's changes
    };
  },
  computed: {
    ...mapState(useStudioStore, {
      studio: 'currentStudio',
    }),
    ...mapState(useUserStore, {
      isLoggedIn: 'isLoggedIn',
      teacherMagicCode: 'teacherMagicCode',
      studentMagicCode: 'studentMagicCode',
      numberOfThisStudioPunches: 'numberOfThisStudioPunches',
      user: 'user',
      teacher: 'teacher',
    }),
    ...mapState(useCommunityStore, {
      numberOfNotifications: 'numberOfNotifications',
      numberOfStudioNotifications: 'numberOfStudioNotifications',
      numberOfChatNotifications: 'numberOfChatNotifications',
      interactAsStudio: 'interactAsStudio',
    }),
    notificationsCount() {
      return this.interactAsStudio
        ? this.numberOfStudioNotifications
        : this.numberOfNotifications;
    },
    getAdminBaseFrontendURL() {
      const host = this.url.host.toLowerCase();
      if (isOfficialDomain(host)) {
        return replaceURLWithCurrentDomain(this.url);
      }

      return process.env.VUE_APP_FRONTEND_ADMIN_BASE_URL;
    },
    dashboardLink() {
      return `${this.getAdminBaseFrontendURL}dashboard?magic_code=${this.teacherMagicCode}`;
    },
    userName() {
      if (!this.user) return '';
      return (
        this.user.student?.first_name || this.$t('GENERAL.LABEL.ANONYMOUS')
      );
    },
    showLogin(): boolean {
      return !this.isLoggedIn && this.selectedPageName !== 'studio-login';
    },
  },
  methods: {
    openMainNav() {
      this.globalsStore.openMainNav();
    },
    openStudioMobileProfileMenu() {
      this.globalsStore.openStudioMobileProfileMenu();
    },
    getNotificationsStyle() {
      const notificationsTrigger = this.$refs.notificationsTrigger;
      if (!notificationsTrigger) return {};
      const boundingRect = notificationsTrigger.getBoundingClientRect();
      if (boundingRect.left - 380 > 0) {
        return {
          left: `${Math.max(boundingRect.left - 380, 0)}px`,
        };
      } else {
        return {
          right: `1px`,
        };
      }
    },
    hideNotifications() {
      this.showNotifications = false;
    },
    openLoginModal() {
      this.$modal.show(StudentLogin, {
        onLoginFn: () => {
          // redirect from home page to studio/student dashboard if on home page
          const redirectToDashboard =
            ['studio-home-page', 'studio-signup'].indexOf(this.$route.name) !== -1;
          if (redirectToDashboard) {
            this.$router.push({ name: 'studio-dashboard' });
          }
        },
      });
    },
    onScroll() {
      if (isTouchDevice()) {
        this.displayLarge = false;
      } else {
        this.displayLarge = window.scrollY < 100;
      }
    },
    logout() {
      this.showPopoverMenu = false;
      const currentRoute = this.$route;
      if (currentRoute.matched.some(record => record.meta.requiresAuth)) {
        this.$router.push({ name: 'studio-logout' });
      } else useUserStore().logout();
    },
    onNotificationIconClick() {
      this.showNotifications = !this.showNotifications;
    },
    onMessageIconClick() {
      this.$router.push({ name: 'studio-community-chat' });
    },
  },
  created() {
    this.selectedPageName = this.$route.name;
  },
  watch: {
    $route({ name }) {
      this.selectedPageName = name;
      this.showNotifications = false;
    },
  },
  mounted() {
    document.addEventListener('scroll', this.onScroll);
    this.onScroll();
  },
  beforeUnmount() {
    document.removeEventListener('scroll', this.onScroll);
  },
});
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';

.shop-topbar {
  background-color: white !important;
  color: $charcoal;
  padding: 0 0.5rem;
  height: $shop-topbar-height;
  align-items: stretch;
  transition: height 0.2s ease-out;
  box-shadow: 0px 1px 5px rgba(50, 50, 50, 0.2);
  z-index: $zindex-fixed + 3;

  &--lg {
    @include media-breakpoint-up(md) {
      height: $shop-topbar-lg-height;
      box-shadow: none;
      transition: height 0.2s ease-out;
    }
  }

  .brand {
    min-width: 120px;
    padding: 0 !important;
  }

  .logo-image {
    :deep(img) {
      padding: 0.5rem 0;
      max-width: 150px;
      @include media-breakpoint-up(md) {
        max-width: 300px;
      }
    }
  }

  .nav-item {
    font-weight: 500;
    .far {
      font-size: 24px;
    }
  }

  @media screen and (max-width: 992px) {
    .custom-collapse {
      position: absolute;
      top: $shop-topbar-height;
      right: 0;
      max-width: 200px;
      border: 1px solid $light-gray;
      background-color: white;

      &.collapse {
        .navbar-nav {
          width: 200px;
          max-width: 200px;
        }
      }
    }
  }
}

.back-button {
  display: flex;
  align-items: center;
  padding: 0 16px;
  margin-left: -10px;
}

.page-tabs {
  position: relative;
  bottom: -1px;
}

.open-main-nav-button {
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  text-align: center;
  cursor: pointer;
  color: $medium-gray;
  &:hover,
  &:active {
    color: var(--brand-color, $coral);
  }
}

.destyled-button {
  border: none;
  outline: none;
  background-color: transparent;
}

.circle {
  background-color: var(--brand-color, $coral);
  height: 19px;
  width: 19px;
  border-radius: 50%;
  border: 2px solid $white;
  text-align: center;
  font-size: 8px;
  font-weight: 700;
  font-family: 'Poppins';
  color: $white;
  position: absolute;
  bottom: -4px;
  right: 10px;
  padding-top: 2px;
  @media screen and (max-width: 768px) {
    right: 2px;
  }
}

.notifications {
  position: fixed;
  right: 1px;

  &.top-offset-lg {
    top: $shop-topbar-lg-height;
    transition: top 0.2s ease-out;
  }

  &.top-offset {
    top: $shop-topbar-height;
    transition: top 0.2s ease-out;
  }
}
</style>
