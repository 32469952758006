<template>
  <div
    class="sidebar-nav-item"
    :class="{
      active: active,
      'sidebar-nav-item-reverse': iconOnRight,
    }"
    v-bind="$attrs"
  >
    <HMIcon class="icon me-2" :icon="icon" v-if="icon" ></HMIcon>
    <i class="icon me-2" :class="fontIcon" v-if="fontIcon"></i>
    <slot></slot>
    <span class="text me-2" v-if="text">
      <span>
        {{ text }}
      </span>
      <span class="beta-tag" v-if="isBeta"> Beta </span>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
    icon: {
      type: Array,
      default: null,
    },
    fontIcon: {
      type: String,
      default: null,
    },
    active: {
      type: Boolean,
      default: false,
    },
    iconOnRight: {
      type: Boolean,
      default: false,
    },
    isBeta: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';

.sidebar-nav-item {
  color: $dark-gray;
  display: flex;
  min-height: 24px;
  align-items: center;
  padding: 0.5rem 8px;
  text-transform: capitalize;
  cursor: pointer;
  &:hover,
  &.active {
    .icon {
      color: var(--brand-color, $coral);
    }
    .text {
      color: $charcoal;
      color: var(--brand-color, $coral);
    }
  }
}

.icon {
  color: $medium-gray;
  width: 24px;
  text-align: center;
}

.text {
  flex: 1;
  display: flex;
  align-items: center;
}

.sidebar-nav-item-reverse {
  flex-direction: row-reverse;
}
</style>
