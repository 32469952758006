<template>
  <footer
    class="footer-fixed bg-charcoal text-white footer-fixed-teacher"
    v-if="showViewAsTeacherFooter"
  >
    <div class="container-fluid h-100 px-2 px-lg-4">
      <div
        class="d-flex flex-row-reverse flex-md-row flex-nowrap justify-content-between align-items-center h-100 mx-2"
      >
        <div class="d-none d-md-flex">
          <i class="fa fa-eye me-2"></i>
          <span class="ms-1">{{ $t('STUDIO.LABELS.STUDENT_VIEW') }}</span>
        </div>
        <div class="d-flex flew-row align-items-center">
          <div class="">
            {{ $t('STUDIO.LABELS.INTERACT_AS') }}
          </div>
          <span class="mx-2 switch-label">{{
            $t('STUDIO.LABELS.STUDIO')
          }}</span>
          <HMSwitch
            color="gray"
            v-model="asStudio"
          />
          <span class="ms-2 switch-label text-truncate">
            {{ studentCommunityProfileFirstName }}
          </span>
        </div>
        <a class="undecorated-router-link" :href="dashboardLink">
          <div
            class="c-white bg-charcoal rounded d-none d-md-inline-flex pointer justify-content-start align-items-center"
          >
            <i class="far fa-long-arrow-alt-left me-2 pt-1"></i>
            {{ $t('STUDIO.LINKS.BACK_TO_ADMIN') }}
          </div>
          <i class="fas fa-long-arrow-alt-left d-inline-flex d-md-none"></i>
        </a>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import { useStudioStore, useUserStore, useCommunityStore } from '@/stores';

import { isOfficialDomain, replaceURLWithCurrentDomain } from '@/utils/url';

export default defineComponent({
  name: 'ViewAsTeacherFooter',
  setup() {
    const communityStore = useCommunityStore();
    const url = useRequestURL();

    return {
      communityStore,
      url,
    }
  },
  computed: {
    ...mapState(useStudioStore, {
      studio: 'currentStudio',
    }),
    ...mapState(useUserStore, {
      teacher: 'teacher',
      teacherStudioUrl: 'teacherStudioUrl',
      teacherMagicCode: 'teacherMagicCode',
      studentCommunityProfileFirstName: 'studentCommunityProfileFirstName',
    }),
    ...mapState(useCommunityStore, {
      interactAsStudio: 'interactAsStudio',
    }),
    asStudio: {
      get() {
        return !this.interactAsStudio;
      },
      set(newValue: boolean) {
        this.communityStore.setInteractAsStudio(!newValue);
      },
    },
    getAdminBaseFrontendURL() {
      const host = this.url.host.toLowerCase();
      if (isOfficialDomain(host)) {
        return replaceURLWithCurrentDomain(this.url);
      }
      return process.env.VUE_APP_FRONTEND_ADMIN_BASE_URL;
    },
    dashboardLink() {
      return `${this.getAdminBaseFrontendURL}dashboard?magic_code=${this.teacherMagicCode}`;
    },
    showViewAsTeacherFooter() {
      return (
        this.teacher &&
        this.teacherStudioUrl === this.studio?.studio_url &&
        !this.$route.meta.hideStudioAdminFooter
      );
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';

.footer-fixed {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: $shop-footer-height;
  line-height: $shop-footer-height;
  background-color: $white;
  z-index: $zindex-fixed + 1;
  font-size: 10px;

  @include media-breakpoint-down(md) {
    font-size: 10px;
  }
}

:deep(.logo-image-fixed-footer) {
  max-height: 10px;
}

.footer-fixed-teacher {
  font-size: 0.875rem !important;
  line-height: 1;
  overflow: hidden;
  font-family: 'Poppins' !important;
  height: 35px;
}

.custom-switch {
  border: 1px solid white;
  box-sizing: content-box;
}

.switch-label {
  font-weight: 500;
  max-width: 250px;

  @include media-breakpoint-down(md) {
    max-width: 150px;
  }
}

:deep(.v-switch-core) {
  border: 1px solid white;
  box-sizing: content-box !important;
}
</style>
