<template>
  <div>
    <span
      v-if="studio.instagram"
      class="me-3 me-lg-4 pt-2"
      v-tooltip="{
        content: $t('STUDIO.TOOLTIPS.INSTAGRAM'),
        delay: 100,
        placement: 'top',
        container: 'body',
      }"
    >
      <a :href="withHttp(studio.instagram)" target="_blank">
        <i class="social-icon fab fa-instagram"></i>
      </a>
    </span>
    <span
      v-if="studio.pinterest"
      class="me-3 me-lg-4 pt-2"
      v-tooltip="{
        content: $t('STUDIO.TOOLTIPS.PINTEREST'),
        delay: 100,
        placement: 'top',
        container: 'body',
      }"
    >
      <a :href="withHttp(studio.pinterest)" target="_blank">
        <i class="social-icon fab fa-pinterest"></i>
      </a>
    </span>
    <span
      v-if="studio.twitter"
      class="me-3 me-lg-4 pt-2"
      v-tooltip="{
        content: $t('STUDIO.TOOLTIPS.TWITTER'),
        delay: 100,
        placement: 'top',
        container: 'body',
      }"
    >
      <a :href="withHttp(studio.twitter)" target="_blank">
        <i class="social-icon fab fa-twitter"></i>
      </a>
    </span>
    <span
      v-if="studio.facebook_page"
      class="me-3 me-lg-4 pt-2"
      v-tooltip="{
        content: $t('STUDIO.TOOLTIPS.FACEBOOK_PAGE'),
        delay: 100,
        placement: 'top',
        container: 'body',
      }"
    >
      <a :href="withHttp(studio.facebook_page)" target="_blank">
        <i class="social-icon fab fa-facebook-f"></i>
      </a>
    </span>
    <span
      v-if="studio.facebook_group"
      class="me-3 me-lg-4 pt-2"
      v-tooltip="{
        content: $t('STUDIO.TOOLTIPS.FACEBOOK_GROUP'),
        delay: 100,
        placement: 'top',
        container: 'body',
      }"
    >
      <a :href="withHttp(studio.facebook_group)" target="_blank">
        <i class="social-icon fab fa-facebook"></i>
      </a>
    </span>
    <span
      v-if="studio.youtube_channel"
      class="me-3 me-lg-4  pt-2"
      v-tooltip="{
        content: $t('STUDIO.TOOLTIPS.YOUTUBE_CHANNEL'),
        delay: 100,
        placement: 'top',
        container: 'body',
      }"
    >
      <a :href="withHttp(studio.youtube_channel)" target="_blank">
        <i class="social-icon fab fa-youtube"></i>
      </a>
    </span>
    <span
      v-if="studio.web_link"
      class="me-3 me-lg-4 pt-2"
      v-tooltip="{
        content: $t('SETTINGS.LABEL.WEBSITE'),
        delay: 100,
        placement: 'top',
        container: 'body',
      }"
    >
      <a :href="withHttp(studio.web_link)" target="_blank">
        <i class="social-icon far fa-globe"></i>
      </a>
    </span>
    <span
      v-if="studio.linkedin_page"
      class="me-3 me-lg-4 pt-2"
      v-tooltip="{
        content: $t('STUDIO.TOOLTIPS.LINKEDIN'),
        delay: 100,
        placement: 'top',
        container: 'body',
      }"
    >
      <a :href="withHttp(studio.linkedin_page)" target="_blank">
        <i class="social-icon fab fa-linkedin"></i>
      </a>
    </span>
    <span
      v-if="studio.tiktok_link"
      class="me-3 me-lg-4 pt-2"
      v-tooltip="{
        content: $t('STUDIO.TOOLTIPS.TIKTOK'),
        delay: 100,
        placement: 'top',
        container: 'body',
      }"
    >
      <a :href="withHttp(studio.tiktok_link)" target="_blank">
        <i class="social-icon fab fa-tiktok"></i>
      </a>
    </span>
  </div>
</template>

<script lang="ts">
import { withHttp } from '@/utils/url';
export default {
  props: {
    studio: {
      type: Object,
      required: true,
    },
  },
  methods: {
    withHttp
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';

a {
  color: var(--brand-color, $coral);
  font-size: 22px;
  &:hover,
  &:active {
    color: var(--brand-color, $coral);
  }
}
</style>
