<template>
  <nav class="studio-mobile-navigation" v-if="studio">
    <NuxtLink
      :to="{ name: 'studio-dashboard' }"
      class="undecorated-router-link nav-item"
      v-if="isLoggedIn"
    >
      <i  class="icon far fa-compass" ></i>
      <small class="mt-1 smallest">
        {{ $t('STUDIO.LINKS.DASHBOARD') }}
      </small>
    </NuxtLink>
    <NuxtLink
      :to="{ name: 'studio-home-page' }"
      class="undecorated-router-link nav-item"
      v-else
    >
      <i  class="icon far fa-compass" ></i>
      <small class="mt-1 smallest">
        {{ $t('STUDIO.LINKS.HOME') }}
      </small>
    </NuxtLink>

    <NuxtLink
      :to="{ name: 'studio-calendar' }"
      class="undecorated-router-link nav-item"
      v-if="studio.has_events"
    >
      <i  class="icon far fa-calendar" ></i>
      <small class="mt-1 smallest">
        {{ $t('STUDIO.LINKS.CALENDAR') }}
      </small>
    </NuxtLink>

    <NuxtLink
      :to="{ name: 'studio-shop' }"
      class="undecorated-router-link nav-item"
    >
      <i  class="icon far fa-box-open" ></i>
      <small class="mt-1 smallest">
        {{ $t('STUDIO.LINKS.SHOP') }}
      </small>
    </NuxtLink>

    <NuxtLink
      :to="{ name: 'studio-community' }"
      class="undecorated-router-link nav-item"
      v-if="isLoggedIn && studio.has_community_groups"
    >
      <i  class="icon far fa-comments" ></i>
      <small class="mt-1 smallest">
        {{ $t('STUDIO.LINKS.COMMUNITY') }}
      </small>
    </NuxtLink>

    <div
      @click="openSettings"
      class="undecorated-router-link nav-item"
      v-if="hasCustomNavLinks"
    >
      <i  class="icon far fa-bars" ></i>
      <small class="mt-1 smallest">
        {{ $t('STUDIO.LINKS.MENU') }}
      </small>
    </div>
  </nav>
</template>

<script>
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import { useStudioStore, useUserStore, useGlobalsStore  } from '@/stores';

export default defineComponent({
  name: 'StudioMobileNavigation',
  computed: {
    ...mapState(useStudioStore, {
      studio: 'currentStudio',
      hasCustomNavLinks: 'hasCustomNavLinks',
    }),
    ...mapState(useUserStore, {
      isLoggedIn: 'isLoggedIn',
    }),
  },
  methods: {
    openSettings() {
      useGlobalsStore().openMainNav(true);
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';

nav {
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid $light-gray;
  z-index: 1032;
}

.nav-item {
  display: flex;
  height: 70px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: $charcoal;

  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.5);
  + .nav-item {
    // border-left: 1px solid $light-gray;
  }
}

.nav-item.router-link-exact-active {
  color: var(--brand-color, $coral);
}

.icon {
  font-size: 22px;
}
</style>
