<template>
  <div class="container pt-5 pb-4 text-center">
    <img
      :src="config['studio-expired-img']"
      alt=""
      class="expired-studio-img p-1"
    />
    <div class="expired-studio-content mx-auto">
      <h1 class="my-4">
        {{ $t('STUDIO.TEXT.STUDIO_EXPIRED_TITLE') }}
      </h1>
      <div v-if="!isTeacher">
        <div class="c-charcoal">
          {{ $t('STUDIO.TEXT.STUDIO_EXPIRED_DESCRIPTION') }}
        </div>
        <a
          v-if="studio.studio_email"
          :href="`mailto:${studio.studio_email}`"
          target="_blank"
          class="btn btn-secondary mt-3"
        >
          {{ $t('STUDIO.TEXT.STUDIO_EXPIRED_CTA') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import { useStudioStore } from '@/stores/studio';
import { getConfig } from '@/utils/configuration';

export default defineComponent({
  name: 'ExpiredStudio',
  props: {
    isTeacher: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const url = useRequestURL();

    return {
      config: getConfig(url)
    }
  },
  computed: {
    ...mapState(useStudioStore, {
      studio: 'currentStudio',
    }),
  },
});
</script>

<style lang="scss" scoped>
.expired-studio-img {
  width: 369px;
  max-width: 100%;
}
.expired-studio-content {
  max-width: 470px;
}
</style>
